html {
    font-size: 62.5%;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;       
    box-sizing: border-box;    
}
*, *:before, *:after {
    box-sizing: inherit;
}

header{
    box-shadow: 0px 4px 10px 0px rgba(102,56,99,0.3)!important;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(102,56,99,0.3)!important;
    -moz-box-shadow: 0px 4px 10px 0px rgba(102,56,99,0.3)!important;   
}

body {
    font-family: 'Ebrima', sans-serif;
    font-size: 1.6rem;
    line-height: 1.8;
}
p {
    color: $negro;
    font-size: 1.6rem;
}

ul{
    li{
        font-size: 1.5rem;
    };
}
.contenedor {
    width: 95%;
    max-width: 120rem;
    margin: 0 auto;
}

.contenedor2 {
    width: 75%;
    max-width: 120rem;
    margin: 0 auto;
}

a {
    text-decoration: none;
}

img,
picture {
    width: 100%;
    display: block;
}

h1, h2, h3 {
    font-family: $fuente_principal;
    margin: $separacion / 2 0;
    font-weight: 300;
    text-align: center;
    
}

h1 {
    font-size: 3.8rem;
}
h2 {
    font-size: 3.4rem;
}
h3 {
    font-size: 2.6rem;
}
h4 {
    font-size: 2.2rem;
}
h5{
    font-size: 1.6rem;
    margin: 0;
    font-weight: normal;
}

//Spinner (indicador de carga)
#contenedor_carga{
    background-color: rgba(250, 240, 245, 0.85);
    width: 100%;
    height: 100%;
    position: fixed;    
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;;
    transition: all 1s ease;
    z-index: 10000;       
}
#carga{
    border: 15px solid #ccc;
    border-top-color: #1abf72 ;
    border-top-style: groove;
    height: 10rem;
    width: 10rem;
    border-radius: 100%; 
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right: 0;
    margin: auto;
    animation: girar 1.5s linear infinite;   
}  

@keyframes girar {
    from { transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

//Fin de sección spinner


.btnmagenta{
    text-decoration: none;
    display: inline-block;
    color: white;
    background-color: #e03a7f;
    margin: 0 auto;
    text-align: center;
    padding: 1.2rem;     
    margin-bottom: 1.4rem;  
    transition: ease-in-out .2s all;
    border-radius: 0.5rem;    
    &:hover{
        background-color: darken(#e03a7f, 10);
    } 
}

.oculto{    
    visibility: hidden;
    font-family: 'Paprika', cursive;
    font-size: 3rem;
}

.contwhatsapp{    
    display: grid;
    grid-template-columns: repeat(2,1fr);
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    z-index: 100;    
    @include tablet{
        margin-top: 0;              
    }
    .Icono_WhatsApp{
        width: 5rem;
        height: 5rem;
        grid-column: 4/5;
        position: relative; 
        @include tablet{
            margin-top: -1rem;
        }                           
    }
    .desplegable1 {
       // position: absolute;                
        background-color:#00D726;  
        border-radius: 0.35rem; 
        text-align: center;   
        display: none;     
        width: auto;  
        grid-column: 3/4;     
        a{
            color: white;           
            transition: ease-in-out .3s all;
            display: inline-block;
            width: 6.7rem;            
            &:last-of-type{
                width: 100%;
            }                   
            &:hover{
                background-color:#00c120;
            }
        }
    }
}

//Usado por el FAQ snippet

.faq li { padding: 20px; }

.faq li.q {
  background: #ffe4fb;
  font-weight: bold;
  font-size: 120%;
  border-bottom: 1px #ddd solid;
  cursor: pointer;
}

.faq li.a {
  background: #3BB0D6;
  display: none;
  color:#fff;
}

.rotate {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }


