//Sección Contacto

.seccioncontacto{
    @include tablet{
        display: flex;
        justify-content: center;
        margin-top: 10rem;
    }  
}

.contparrafoini{
    p{
        width: 75%;        
        margin: 2rem auto; 
        color: $rosaoscuro;                 
        a{
           color: $rosaoscuro;            
           &:visited{
            color: $rosaoscuro;
           }
           .imgwhatsapp{
            display: inline-block;            
            width: 2rem;  
            margin-bottom: -0.3rem;          
            @include tablet{
                margin-top: 2rem;
            }            
        } //end imgwhatsapp
        } // end a        
    } //end p
}

.cont2{
    color: $rosaoscuro;
    margin: 0 0 3.7rem 6rem;
    @include tablet{
        margin-top: 5rem;
    }
    p{
       color: $rosaoscuro;
       a{
        color: darken($rosaoscuro, 10%);
        &:visited{
           color: darken($rosaoscuro, 10%);
        }
       }       
    }
}

.contformulario{ 
    @include tablet{
        width: 50%;   
    }
    
    .inforequired{
        text-align: center;
        color: $rosaoscuro;            
    }
    form{
        width: 100%;
        max-width: 60rem;
        margin: 0 0 0 1.25rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-items: flex-start;
        @include tablet{
            margin: 0 auto;
        }
        label{
            color: $rosaoscuro;
            font-weight: bold;                        
        }
        input{
            width: 90%;
            height: 3rem;
            margin: 0.5rem;
            margin-bottom: 2rem;
        }
        textarea{
            width: 91%;
            height: 11rem;
            margin-bottom: 2rem;
        }
        select{
            color: $rosaoscuro;
            margin-bottom: 1.5rem;
        }
        button{
            padding: 0.5rem 1rem;
            border: none;
            background: $rosaoscuro;
            color: white;
            border-radius: 6%;
            cursor: pointer;
            transition: all .3s ease;
            margin-top: 1.5rem;
            margin-bottom: 2rem;                        
            &:hover{                
               background-color: darken($rosaoscuro,10%);
            }
        } //end button       
    } // end form
}

.error{ 
    background-color: rgb(199, 49, 49)!important;
    padding: 0.5rem;
    font-size: 1.5rem;
    color: white!important;
    text-align: center;    
}

.mensajeexito{
    background-color: rgb(49, 199, 49)!important;
    padding: 0.5rem;
    font-size: 1.5rem;
    color: white!important;
    text-align: center;  
}

.phpsuccess{
     width: 90%;     
    @include tablet{
       width: 50%;
    }        
    margin: 2rem auto 2rem auto;
    margin-bottom: 2rem;
}

.h1contacto{
    font-size: 2rem;
    color: $rosaoscuro;
    @include tablet{    
        font-size: 2.5rem;
    }
}

.contenedorsent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60.3rem;
    @include tablet{
        height: 40.3rem;
    }
    .btnvolver{
        padding: 0.5rem 1rem;
        border: none;
        background: $rosaoscuro;
        color: white;
        border-radius: 6%;
        cursor: pointer;
        transition: all .3s ease;
        margin: 0 auto;
        margin-bottom: 2rem;            
        &:hover{                
           background-color: darken($rosaoscuro,10%);
              }
    }
}
