footer{
    background-color: #EFBAC0;
    padding: 1.2rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    height: auto;
    p{
        text-shadow: 1px 2px 3px rgba(133, 44, 104, 0.45);
        color: white;
        text-align: center;
        margin: 0 auto;        
        padding: 0.8rem 0;              
        width: 99%;
        font-family: 'Ebrima', arial;        
        font-size: 1.5rem;
        @include tablet{            
            font-size: 1.7rem;
            margin: 0;
        }
        
    }
    .copyright1{
        grid-column: 2/3;
        grid-row: 1/2;
    }
    .copyright2{
        margin-top: -1.5rem;                
        @include tablet{
            margin-top: -2.7rem; 
            margin-bottom: -8rem;       
        }        
        grid-column: 2/3;
        grid-row: 2/3;
    }
    .desarrolladopor{
        grid-column: 2/3;
        grid-row: 3/4;
        @include tablet{
            margin-bottom: -8rem;
        }       
    }    
    .redes{
        grid-column: 1 / 2;
        margin-top: -2.2rem;  
        @include tablet{
            margin-top: -2.7rem;  
        }             
        justify-content: space-between;
        text-align: center;
    }
    @include tablet{       
        justify-content: space-between;
        align-items: center;
    }  
    
    .fa-facebook{
        color: white;  
        text-shadow: 1px 2px 3px rgba(133, 44, 104, 0.45);      
        font-size: 4rem;
        margin-top: 2rem;
    }
    .fa-instagram-square{
        color: white;
        text-shadow: 1px 2px 3px rgba(133, 44, 104, 0.45);
        font-size: 4rem;
        margin-top: 2rem;
        margin-left: 1.5rem;
    }  
}
