.seccion {
    margin-top: $separacion / 2;
    margin-bottom: $separacion / 2;
}
.contenido-centrado {
    max-width: 80rem;
}

.alinear-derecha{
    display: flex;
    justify-content: flex-end;
}

.w-100{
    width: 100%;
}

.contenedor-boton-centrado{
    display: flex;
    justify-content: center;
}

.textooculto{
    display: none;
    @include tablet{
        display: block;
        visibility: hidden;
    }     
}

.anchoroculto{
    visibility: hidden;
    display: block;
    @include tablet{
        height: 17rem;
    }
    
}

.anchoroculto2{
    visibility: hidden;
    display: block;
    height: 22rem;
    @include tablet{
        height: 36rem;
    }    
}