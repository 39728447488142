
.seccionfaq{
//bajar el contenido para que el menú flotante no lo tape         
  @include tablet{
    margin-top: 10rem;
  }
}

.faq{
    padding-left: 2rem;
    @include tablet{
        
    }
}
.faq li { padding: 20px;
list-style: none;
}

.faq li.q {
  background: white;
  font-weight: bold;
  font-size: 110%;
  color: $rosaoscuro;
  border-bottom: 1px #ddd solid;
  transition: all .4s ease;
  cursor: pointer; 
  img{
    width: 1.5rem;
    display: inline-block;
    transition: all .2s ease;
  }
  &:hover{
    color: darken($rosaoscuro , 10%);
  }
}

.faq li.a {
  background: rgb(248, 248, 248);
  display: none;
  color: $rosaoscuro;
  .panswer{
    color: $rosaoscuro;
    font-weight: bold;  
  }
  .panswer2{    
    width: 45%;
  }
  a{
    color: darken($rosaoscuro , 10%);
}
  a:visited{
    color: darken($rosaoscuro , 10%);
  }
  ul{
    list-style: none;
    li {
       background: rgb(248, 248, 248);  
       color: $rosaoscuro;
       padding: 0;
       line-height: 2;
    }
  }
}

.rotate {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .imglogofaq{
    width: 17rem;
    margin: auto;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .h1faq{
    color: $rosaoscuro;
    text-shadow: 1px 2px 3px rgba(133, 44, 104, 0.45);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px); 
    font-size: 3.2rem;
    @include tablet{
        font-size: 3.8rem
    } 
  }