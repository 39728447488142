.serpartestaff{
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffc9c9+0,ffe2e7+100 */
    background: #ffc9c9; /* Old browsers */
    background: -moz-linear-gradient(top,  #ffc9c9 0%, #ffe2e7 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #ffc9c9 0%,#ffe2e7 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #ffc9c9 0%,#ffe2e7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc9c9', endColorstr='#ffe2e7',GradientType=0 ); /* IE6-9 */
    .h1SPS{                    
        margin-top: 0;
        width: 90%;
        margin-left: 1.7rem;
        margin-top: 2.5rem;   
        margin-bottom: 5rem;     
        @include tablet{    
            margin-top: 0;
            padding-top: 3rem;
            width: auto;
            margin-left: 0;
            font-size: 3.2rem;
            margin-bottom: 10rem;
        }         
        color: $magenta;
        font-family: $fuente_principal;
        font-size: 2.05rem;
        &::after{
            position: absolute;
            content: "";    
            margin: 0 auto;
            margin-top: 4.4rem;
            left: 0;
            right: 0;
            width: 60%;      
                @include tablet{      
                    width: 20%;
                    margin-top: 6rem;
            }
            
            height: 0.5rem;        
            background-color: #b959ff;
            transition:.5s;    
        } 
        &:hover::after{ 
            @include tablet{
                width: 30%; 
            }               
            background-color: #c98cf7;           
        }
} //end h1SUF
}

.contserparte{    
    background-color: #ffc1c7;    
    .h1serparte{
        font-size: 3rem;
        color: $blanco;
        margin-bottom: 0;
        text-align: center;
        text-shadow: 1px 2px 3px rgba(133, 44, 104, 0.45);
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);  
        @include tablet{
            font-size: 3.8rem;
            grid-column: 2/3;
            grid-row: 2/3
        }       
    } //end h1serparte  
    .h3serparte{
        color: $blanco;
        margin: 0 auto;
        font-size: 2rem;
        margin-top: 1.2rem;
        @include tablet{            
            font-size: 2.6rem;
            grid-column: 1/4;
            grid-row: 2/3;
            margin-top: 12rem;
            width: 70%;
        }
    } //end h3serparte
    p{
        color: $blanco;
        font-size: 1.8rem;
        margin-bottom: 0;
        padding-top: 4rem;
        text-align: center;
        text-shadow: 1px 2px 3px rgba(133, 44, 104, 0.45);
        backdrop-filter: blur(0px);
        width: 99%;
        -webkit-backdrop-filter: blur(0px);  
        @include tablet{
            font-size: 2rem;
            padding-top: 0;
        }
    }  
    .contparrafobsas{
        @include tablet{
            grid-row: 3/4;
            grid-column: 1/2;
        }
        .parrafobsas{
        @include tablet{
            grid-row: 3/4;            
            margin-bottom: 4rem;
        } //end include
       } //end parrafobsas
       .btnmagenta{
        margin: 2rem auto 0 9rem;
        width: 50%; 
        font-size: 1.75rem;
        @include tablet{
            display: block;
            margin: 0 auto;
            width: 38%;
            grid-row: 3/4;                
        } //end include  
    } //end btnmagenta
    } //end contparrafobsas
    
    .contparrafocordoba{
        @include tablet{
            grid-row: 3/4;
            grid-column: 2/3;
        }
        .parrafocordoba{
            @include tablet{                             
                margin-bottom: 4rem;
            } //end include
        } //end parrafocordoba
       .btnmagenta{
        margin: 2rem auto 0 8.7rem;
        width: 50%; 
        font-size: 1.75rem;
        @include tablet{
            display: block;
            margin: 0 auto;
            margin-top: 4rem;
            width: 40%;
           // width: 35%;            
        } //end include  
    } //end btnmagenta
    } //end contparrafobsas

    .contparrafoneuquen{
        @include tablet{
            grid-row: 3/4;
            grid-column: 3/4;
        }
        .parrafoneuquen{
        @include tablet{            
            margin-bottom: 4rem;
        } //end include
       } //end parrafobsas
       .btnmagenta{
        margin: 2rem auto 5rem 9rem;
        width: 50%; 
        font-size: 1.75rem;
        @include tablet{
            margin: 2rem auto 0 9rem;
            display: block;
            margin: 0 auto;
            width: 38%;
            grid-row: 3/4;                
        } //end include  
    } //end btnmagenta
    } //end contparrafoneuquen
    
    // .parrafoneuquen{
    //     @include tablet{
    //         grid-row: 3/4;
    //         grid-column: 3/4;
    //         padding-left: 10rem;
    //     } //end include
    // } //end parrafocordoba 


    .imglogotipo{
        width: 52%;
        padding-top: 2rem;  
        margin: 0 auto;      
    }
    @include tablet{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        .contlogotipo2{
            grid-column: 2/3;
            grid-row: 1/2;
            .imglogotipo{
                grid-column: 2/3;
                width: 17rem;
                margin: 0 auto;
                margin-top: 10rem;
            } //end imglogotipo
        } //end contlogotipo2
    }  //end include tablet  
} //end contserparte