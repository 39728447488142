.header{
    .navprincipal{        
        @include tablet{                  
            &.fijo{
                position: fixed;
                width: 100%;                
                top: 0;  
                z-index: 100; 
               box-shadow: 0px 4px 10px 0px rgba(102,56,99,0.3)!important;
               -webkit-box-shadow: 0px 4px 10px 0px rgba(102,56,99,0.3);
               -moz-box-shadow: 0px 4px 10px 0px rgba(102,56,99,0.3); 
            }
    } 
    }    
  //  background-color: white; 
    z-index: 100000;   
}

.contenedor-header{
    display: grid;
    column-gap: 1rem;
    justify-content: space-between;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(3, 7rem);
    width: 100%;    
    div {
        grid-column: 4 / 7;
        grid-row: 1/4;
    } 
    .contwhatsapp{
        grid-column: 2 / 3;
        margin-top: 1.5rem;
        @include tablet{
            margin-top: 0;
        }
        .Icono_WhatsApp{
            width: 5rem;
            height: 5rem;
            position: relative; 
            @include tablet{
                margin-top: -1rem;
            }                           
        }
        .desplegable1 {
            position: absolute;                
            background-color:#00D726;  
            border-radius: 0.35rem; 
            text-align: center;   
            display: none;            
            a{
                color: white;
                font-weight: bold;
                transition: ease-in-out .3s all;
                display: inline-block;
                &:last-of-type{
                    width: 100%;
                }                   
                &:hover{
                    background-color:#00c120;
                }
            }
        }
    }     
}

// .icono-celular{
//     max-width: 3rem;
//     max-height: 5rem;
//     margin-left: 1rem;
//     margin-top: 2.5rem;
//     @include tablet{
//         max-width: 4rem;
//         max-height: 6rem; 
//         margin-top: 1rem;
//         transition: ease-in-out 0.2s all;
//         &:hover{
//             transform: scale(1.03) rotate(5deg);
//         }    
//     }    
// }

.contmobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    height: 15rem;
    background-color: #EFBAC0;
    padding-top: 2rem;
    .fa-bars{
        margin-top: 2.5rem;
        margin-bottom: 0.5rem;
        font-size: 5rem;
        text-align: center;    
    }
    @include tablet{       
       display: none;       
    }
    div {
        display: inline-block;
    }
}

.navprincipal{
    .contmenuprin{
        display: none;
        height: auto;
        background-color: #EFBAC0;     
        // line-height: 1;
        // margin-top: 1rem;
        @include tablet{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 10rem;
            // box-shadow: 0px 4px 10px 0px rgba(102,56,99,0.3)!important;
            // -webkit-box-shadow: 0px 4px 10px 0px rgba(102,56,99,0.3);
            // -moz-box-shadow: 0px 4px 10px 0px rgba(102,56,99,0.3);   
        }
    }   
    a{          
        font-family: "Ebrima", sans-serif;
        width: 100%;
        display: block;     
        text-align: center;
        text-decoration: none;     
        color: white;       
        height: 4rem;        
        transition: ease-in-out .3s all;
        padding: 0.5rem;       
        @include tablet{
            display: unset;
            width: auto;
            font-weight: normal;
            &:first-of-type{
                margin-left: 1.5rem;
            }
            &:last-of-type{
                margin-right: 1.5rem;
            }
            &:not(:first-child):hover{            
                color: rgba($color: #FFF, $alpha: 0.7);                
            }
    }
    }
}

.logotipo{        
    max-width:10rem;
    max-height: 10rem; 
   // margin-top: 0.9rem;   
    //margin-right: 2rem;   
    display: none; 
    @include tablet{
        // max-width: 15rem;
        // max-height: 15rem;
        display: block;
        // margin-top: 0.5rem; 
        // margin-bottom: 0.5rem; 
    }
    }

.logotipomob{
    max-width: 9rem;
    max-height: 9rem; 
    display: inline-block;
     margin-left: 0;
     margin-top: -2.7rem;     
    @include tablet{        
        display: none;        
    }
}

.banner1{
    height: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    @include tablet{
        height: 50rem;
        display: grid;
        grid-template-rows: repeat(8, 1fr);
        margin-top: 10rem;
    }          
    max-width: 100%;
    background-image: url('../../imagenes/BANNER_INICIO.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include tablet{
        background-position: unset;
    }

    .bienvenidos{
        color: $blanco;
        width: 95%;
        font-size: 4.4rem;
        line-height: 1.2;
        padding: 1rem;              
        @include tablet{
            padding: 0;
            grid-row: 6 / 7;        
            font-size: 7rem;
        }  
        background-color:rgba(255,255,255, .3);        
        text-align: center;
        font-family: $fuente_sec;
    }
    .lema{
        color: white;        
        background-color: rgba(247, 143, 176, .3);
        width: 95%;
        line-height: 1.2;
        font-size: 2.6rem;
        font-family: "Lato", sans-serif;        
        @include tablet{
            grid-row: 6 / 7;        
            width: 50%;
            line-height: 1.15;
            font-size: 3.5rem;
            font-style: italic;
            margin-top: -5.7rem;
        }
        margin: 0 auto;
        // font-family: $fuente_principal;
    }
}

.contlogotipo1{
    display: none;
    @include tablet {
        display: block;
    }
}

#menumobile{    
    margin-top: 3rem;
}