.contmediosycomunidad{ 
    @include tablet{    
        display: flex;
        flex-direction: column;
        justify-content: center;    
        height: 43.65rem;     
    } //end include
    .h1myc{
        font-size: 3rem;
        color: $rosaoscuro;
        text-shadow: 1px 2px 3px rgba(133, 44, 104, 0.45);
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);  
        @include tablet{
            font-size: 3.8rem;
            grid-column: 2/3;
            margin-bottom: 0;
        }
    }
    .h4myc{
        font-size: 2rem;
        font-weight: 300;
        color: $rosaoscuro;
        text-align: center;
        @include tablet{
            font-size: 2.2rem;
            font-weight: 300;
            text-align: left;
            grid-column: 1/4;
            grid-row: 2/3;
            margin: 0 auto;
            margin-top: 2.5rem;
        }
    }
    .linkmyc{
        display: inline-block;
        text-align: center;
      //  margin: 0 auto;
        font-size: 2rem;
        margin-bottom: 2rem;
        transition: ease-in-out all 0.3s;
        margin-left: 5.2rem;
        &:hover{
            scale: 1.10;           
        }
        @include tablet{
            font-size: 2.2rem;          
            margin-top: 1rem;            
        } //end include
    } // end linkmyc
    .cont1{        
        @include tablet{
            grid-column: 1/2;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .imgmyc{
            width: 18rem;
            margin: 0 auto;           
        }
        .pmyc{
            color: $rosaoscuro;
            font-size: 1.8rem;
            text-align: center;
            width: 95%;
            padding-bottom: 0;
            margin-bottom: 0;
            @include tablet{  
                padding-bottom: 0;          
            }
        }   
    } //end cont1
    .cont2{
           @include tablet{
             margin-left: 5rem;
             padding-left: 7.5rem;
           }          
          .pmyc{    
             color: $rosaoscuro;
             font-size: 1.8rem;
             text-align: center;
             width: 95%;
             padding-bottom: 3rem;
             @include tablet{  
                padding-bottom: 0; 
                text-align: left;         
            }
    } //end pmyc
    .pmyc2{
        margin-left: 2rem;
        @include tablet{
            margin-left: 0;
        }
    }
    .amyc{
        color: $rosaoscuro;
    }
 }//end cont2
 .cont3{
    .h4myc{
        text-align:center;
    }
    .contredes{
        display: flex;
        justify-content: center;
        @include tablet{
            margin-top: 4rem;
        }
        .iconoredes{
            width: 5.5rem;
          } //end iconoredes
    } //end contredes 
     
 } //end cont3
} //end contmediosycomunidad