.serunfranquiciado{
    //bajar el contenido para que el menú flotante no lo tape         
    @include tablet{
    margin-top: 10rem;
  }
    .h1SUF{                    
        margin-top: 0;
        width: 90%;
        margin-left: 1.7rem;
        margin-top: 2.5rem;   
        margin-bottom: 5rem;     
        @include tablet{    
            margin-top: 4rem;
            width: auto;
            margin-left: 0;
            font-size: 3.2rem;
            margin-bottom: 10rem;
        }         
        color: $rosaoscuro;
        font-family: 'Ebrima', cursive;
        font-size: 2.05rem;
        text-shadow: 1px 2px 3px rgba(133, 44, 104, 0.45);
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);        
} //end h1SUF
.contgrid{
    display: grid;
    grid-template-columns: 1;    
    @include tablet{
        grid-template-columns: repeat(2, 1fr);
    }
    .conttextoylogo{
        width: 90%;
        .logosec2{
            max-width: 15rem;
            max-height: 15rem;
            margin: 0 auto; 
            @include tablet{
              margin: 0 auto 2rem auto; 
              max-width: 21rem;
              max-height: 21rem;   
            }          
        }
        ul{
            margin-top: 7rem;            
            list-style: none;
            padding-left: 0;
            @include tablet{
                padding-left: 4rem;
            } 
            li{
                font-family: $fuente_principal;
                font-size: 1.7rem;
                color: $rosaoscuro;
                text-align: center;
                width: 95%;
                margin-bottom: 3rem;
                padding-bottom: 2rem;
                border-bottom: 1px solid #fab5cb;
                i{
                  display: inline-block;
                  @include tablet{
                    display: none;
                  }  
                }
                span{
                    color: $rosaoscuro;
                    font-size: 2rem;
                    display: none;
                    @include tablet{
                        display: inline-block;
                    }
                }
            };
        }
    } // end conttextylogo
    .contimagen1{
        width: 85%;
        margin-bottom: 2.5rem;
        margin: 0 auto;        
        margin: 3rem auto 1.5rem auto;        
        @include tablet{
            margin: 0 auto 3rem auto;            
        }
    }
}

.bannerserfranquiciado{
        background-image: url('../../imagenes/banner-serfranquiciado.png');
        background-position: center center;
        background-size: cover;
        height: 39.9rem;
        width: 100%;
        margin: 0 auto;        
        margin: 3rem auto 1.5rem auto;        
    .transparenciarosa{
            height: 40rem;
            width: 100%;
            margin-top: 0;        
            position: relative;
            background-color: rgba(247, 143, 176, 0.65);        
            margin-top: 7rem;           
            .contserfranquiciado{
                display: grid;
                grid-template-rows: repeat(2,1fr);
                height: 40rem;                
                h3{
                    color: $blanco;
                    font-size: 2rem;
                    @include tablet{
                        font-size: 2.6rem;
                    }               
            }
            .btnmagentaSUF{
                text-decoration: none;
                display: inline-block;
                color: white;
                height: 5.5rem;
                background-color: #e03a7f;
                margin: 0 auto;
                text-align: center;                
                padding: 1.2rem;     
                margin-bottom: 1.4rem;  
                transition: ease-in-out .2s all;
                border-radius: 0.5rem;    
                &:hover{
                    background-color: darken(#e03a7f, 10);
                } 
            }
            }                                 
            }            
    }    
}
// 

