.logosec1{
    max-width: 15rem;
    max-height: 15rem; 
    margin: 3rem auto 0 auto; 
    @include tablet{
      margin: 5rem auto 2rem auto;    
    }          
}

.servicios{
  background-color: $blanco;
}
.h2serv{
    font-family: $fuente_principal;
    color: $rosaoscuro;
    
    text-shadow: 1px 2px 3px rgba(133, 44, 104, 0.45);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);  

    width: 80%;
    margin: 0 auto;   
    font-size: 3rem;
    @include tablet{
      width: 45%;
      font-size: 3.3rem;
    }        
}

.h2comotrabajamos{
  margin-top: -22rem;
  width: 90%;
  margin-left: 1.7rem;
  @include tablet{    
    margin-top: -18rem;
    width: auto;
    margin-left: 0;
  }  
  margin-bottom: 2rem;
  color: $rosaoscuro;
  font-family: $fuente_principal;
  font-size: 2.5rem;
  text-shadow: 1px 2px 3px rgba(133, 44, 104, 0.45);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);   
}

.contserv{
    display: grid;
    width: 100%;
    margin: 0 auto;
    grid-template-columns: 1;
    background-color: #fff;
    color: $rosaoscuro;
    @include tablet{
      grid-template-columns: repeat(1, 1fr);    
    }    
    .servblanco{   
        background-color: $blanco;     
        margin-top: 3rem;    
        padding: 0 2rem;
        @include tablet{  
          // display: flex;        
          // justify-content: space-around;
          height: 42rem;
          // align-items: center;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          .servblancoimg{
            display: flex;           
            align-items: center;
            justify-content: center;
            margin: 0 auto;            
          }
          .pcapacitacion{
             width: 70%;
             line-height: 2.3;
             text-align: center;
             margin: 0 auto;
          }          
          .imgcapacitacion{
            width: 100%;
            max-width: 100%;
            margin-top: 10rem;
          }  
        } //end include tablet              
        .h3caseros{
          @include tablet{        
            margin-top: 9rem;
          }
        }    
        .textocaseros{
          @include tablet{          
            width: 48%;
            line-height: 2.3;
            margin: 0 auto;
          }
        }   

        h3{
            color: $rosaoscuro;                       
            text-shadow: 1px 2px 3px rgba(133, 44, 104, 0.45);
            backdrop-filter: blur(0px);
            -webkit-backdrop-filter: blur(0px);            
            
            font-family: $fuente_principal;
            font-size: 2.5rem;
        }
        p{
          font-family: $fuente_principal;
            font-size: 1.6rem;
            color: $rosaoscuro;
        }
        ul{                     
          color: $rosaoscuro;
            li{
              @include tablet{
              font-size: 1.6rem;
            }   
            };
        };
        .h3capacitacion{
          @include tablet{                    
            margin-top: 8rem;
          }
        }
        .h3hogar{
          @include tablet{                    
            margin-top: 3.3rem;            
          }
        }  
        .textohogar{
          @include tablet{                    
            width: 70%;
            line-height: 2.3;
            margin: 0 auto;
          }        
        }     
        .imgserv{          
          width: 100%;
          margin-bottom: 2rem;          
          transition: ease-in-out .35s all;
          border-radius: 90%;
          &:hover{
            transform: scale(1.05);
          }
          @include tablet{
            max-width: 52%;
          }
        }
        .imgentret1{          
          margin-top: 2.6rem;
          max-width: 75%;
        }
        .imagenminimagotoi{
          margin-top: 5.4rem;          
        }
        .imgninieras{
          margin-top: 2.1rem;
        }

        .imgcapacitacion{
          @include tablet{
            max-width: 80%;
          }
        }

        .textoadultos{
          @include tablet{          
            width: 75%;
            line-height: 2.3;
            margin: 0 auto;
          }
         }  
    }

    .servrosa{
      background-color: #EFBAC0;
      margin-top: 3rem;    
      padding: 0 2rem;

    .servcuidado{
      @include tablet{
        margin-top: 5.7rem;
      }
    }

      @include tablet{  
        // display: flex;        
        // justify-content: space-around;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        height: 42rem;
        // align-items: center;
        .servrosaimg{
          display: flex;           
          align-items: center;
          justify-content: center;
          margin: 0 auto;            
        }
      }       

      h3{
          // color: $blanco;  
          color: $rosaoscuro;                          
         // text-shadow: 1px 2px 3px rgba(133, 44, 104, 0.45);
          text-shadow: -2px 3px 7px #FFFFFF;
          backdrop-filter: blur(0px);
          -webkit-backdrop-filter: blur(0px);               
          font-family: $fuente_principal;
          font-size: 2.5rem;
      }
      p{
          font-family: $fuente_principal;
          font-size: 1.6rem;
          @include tablet{
            width: 70%;
            margin: 0 auto;
          }
          // color: $blanco;
          color: $rosaoscuro;
          text-shadow: -2px 3px 7px #FFFFFF;
      }

      .h3ninieras{
        text-align: center;
      }
      .pninieras{   
        text-align: center;          
        @include tablet{
          width: 80%;
          line-height: 2.3;         
          margin-left: 6.2rem;
        }      
      }

      .h3entret{
        @include tablet{
           margin-top: 9rem;   
        }       
      }
      .textoentret{
        @include tablet{
          width: 70%;
          line-height: 2.3;
          margin: 0 auto;
        }
      }
      .h3minimagotoi{
        @include tablet{                    
          margin-top: 8.4rem;
        }
      }
      .textominimagotoi{
        @include tablet{
          width: 50%;
          line-height: 2.3;
          margin: 0 auto;
        }
       }            
      ul{                     
        color: $blanco;
          li{
            @include tablet{
            font-size: 1.6rem;
          }   
          };
      };
      .imgserv{          
        width: 100%;        
        margin-bottom: 2rem;          
        transition: ease-in-out .35s all;
        border-radius: 90%;
        &:hover{
          transform: scale(1.05);
        }
        @include tablet{
          max-width: 52%;          
        }

        @include desktop{
        // max-width: 20rem;
        }

      }
      .imagencuidado{
         // margin-top: 2.6rem;
      @include tablet{
        margin-top: -9rem;
      }   
      }
      .imagenminimagotoi{
        margin-top: 5.4rem;
      }
      .imgninieras{
        margin-top: 2.1rem;
        @include tablet{
          width: 83%;
          max-width: 100%;
        }
      }
      }
        }
        .imgentret1{
          margin-top: 2.6rem;
        }
        .imagenminimagotoi{
          margin-top: 5.4rem;
        }
        .imgninieras{
          margin-top: 2.1rem;
        }
    

.h2consult{
      color: $rosaoscuro;
      font-family: $fuente_principal;
      font-size: 3rem;
      margin-bottom: 0;
      margin-top: 5rem;
 
      text-shadow: 1px 2px 3px rgba(133, 44, 104, 0.45);
       backdrop-filter: blur(0px);
      -webkit-backdrop-filter: blur(0px);
      
         
      }    
    
 .contservninieras{
  margin-top: -9rem;
  @include tablet{
    margin-top: -15rem;
  }   
  
 }     

// Sección Cómo trabajamos

.contcomotra{
  display: grid;
  grid-template-columns: 1fr;
  @include tablet{
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
    margin: 0 auto;
  }
  .contenedor{
    h3{
      color: $rosaoscuro;
      font-family: $fuente_principal;
      font-size: 2.2rem;
      i{
        font-size: 2.9rem;  
        margin-right: 1.2rem;      
      }
    }
    ul{
      padding-left: 1.5rem;
      @include tablet{
        padding-left: 4rem;
      }
      li{
        font-family: $fuente_principal;
        font-size: 1.5rem;
        color: $rosaoscuro;
        text-align: center;
        width: 95%;
      }
    }
    p{
      font-family: $fuente_principal;
      font-size: 1.6rem;
      color: $rosaoscuro;
      text-align: center;
      @include tablet{
        text-align: left;
      }
      a{
        color: #c780a5;
        border-bottom: 2px dotted #c780a5;
      }
    }
  }   
}
.contservfinal{
  margin-bottom: 5rem;
}

.lineaseparadora{
  width: 95%;
  height: 120%;
  display: none;
  @include tablet{ 
    display: block;   
    width: 80%;
    height: 40%;
  }
  margin: 0 auto;
  margin-top: 6rem;
  margin-bottom: 12rem;
}

.lineaseparadoracel{  
  width: 80%;
  margin: 0 auto;
  margin-bottom: 3rem;
  display: block;
  @include tablet{
    display: none;
    }
 }
  

// Sección Entretenimiento

.h2entretenimiento{
  font-size: 2.2rem;
  margin-left: 2rem; 
  color: $rosaoscuro; 
  @include tablet{    
    font-size: 2.5rem;
    margin-left: 0;  
  }
  
}

.contentre{
  margin-top: 4rem;
  margin-bottom: 4rem;  
  @include tablet{
    width: 86%;
  }
  p{
    font-family: $fuente_principal;
    font-size: 1.6rem;
    width: 92%;
    margin-left: 0.8rem;
    color: $rosaoscuro;
    text-align: center;
    @include tablet{
      text-align: left;
      width: 100%;
      margin-left: 0;
    }
    span{
      font-weight: bold;
    }
  }
  ul{
    list-style-type:disc;
    li{
      font-size: 1.5rem;
      color: $rosaoscuro;
      font-family: $fuente_principal;
      margin-left: 0.5rem;
      @include tablet{
        margin-left: 0;
      }
    };
  }
  h3{
    color: $rosaoscuro;
    font-family: $fuente_principal;
    text-shadow: 1px 2px 3px rgba(133, 44, 104, 0.45);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);  
    // &::after{
    //   position: absolute;
    //   content: "";    
    //   margin: 0 auto;
    //   margin-top: 5rem;
    //   left: 0;
    //   right: 0; 
    //   width: 48%;      
    //   @include tablet{      
    //     width: 11%;
    //   }       
    //   height: 0.5rem;        
    //   background-color: #b959ff;
    //   transition:.5s;    
    // } 
    // &:hover::after{    
    //     width: 20%; 
    //     background-color: #c98cf7;   
    // }    
  }
}

.contcuidado{   
  height: 68rem!important;
  @include tablet{
    height: 54rem!important;   
  }  
}  

.oculto{
  display: none;
  @include tablet{
    display: none;    
  }
}


/* Slideshow container */
.slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
  }
  
  /* Hide the images by default */
  .mySlides {
    display: none;
  }
  
  /* Next & previous buttons */
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }
  
  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.8);
  }
  
  /* Caption text */
  .text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
  }
  
  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }
  
  /* The dots/bullets/indicators */
  .dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  
  .active, .dot:hover {
    background-color: #717171;
  }
  
  /* Fading animation */
  .fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
  }
  
  @-webkit-keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
  }
  
  @keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
  }