@import 'base/normalize';
@import 'base/variables';
@import 'base/mixins';
@import 'base/globales';
@import 'base/utilidades';
@import 'base/header';
@import 'base/seccion1';
@import 'base/adnimagotoi';
@import 'base/serunfranquiciado';
@import 'base/serpartestaff';
@import 'base/mediosycomunidad';
@import 'base/footer';
@import 'base/faq';
@import 'base/contacto';



