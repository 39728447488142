.AdnImagotoi{  
    //bajar el contenido para que el menú flotante no lo tape         
    @include tablet{
        margin-top: 10rem;
    }
    .imgAdn{        
        margin: 0 auto;
        border-radius: 1rem;
        margin-bottom: 2rem;  
        max-width: 50rem;  
        border-radius: 100%;
        width: 92%;     
        @include tablet{
            max-width: 50rem;       
            height: 80%;
            margin: 0 auto 2rem 2rem;
        }
    }
    .h1Adn{                    
        margin-top: 0;
        width: 90%;
        margin-left: 1.7rem;
        margin-top: 1.5rem;        
        @include tablet{    
            margin-top: 4rem;
            width: auto;
            margin-left: 0;
            font-size: 3.2rem;
            margin-bottom: 4rem;
        }  
        margin-bottom: 2rem;
        color: $rosaclaro;
        text-shadow: 1px 2px 3px rgba(133, 44, 104, 0.45);
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px); 
        font-family: 'Ebrima', cursive;
        font-size: 2.5rem;    
} //end h1Adn

    .contenedor{
        p{           
            font-family: 'Ebrima', cursive;
            font-size: 1.53rem;
            text-align: center;        
            color: #f26f98;
            margin-top: 2rem;
            width: 90%;
            margin-left: 1.5rem;            
            @include tablet{
                margin-top: 0;
                margin-left: 5rem;
            }
        };
        .h2Adn{
            color: $rosaoscuro;
            font-family: 'Ebrima', cursive;
            font-size: 2rem;
            text-shadow: 1px 2px 3px rgba(133, 44, 104, 0.45);
            @include tablet{
                font-size: 2.5rem;
            }
            
        }
    } // end contenedor

} //end section AdnImagotoi

.contadn{
    @include tablet{
       display: flex;
       justify-content: space-between;
    }    
}