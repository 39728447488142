
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

// Fuentes
@font-face {
    font-family: 'Ebrima';
    src: url('../../../fuentes/ebrima.ttf') format("truetype");   
    font-style: normal;
    font-weight: 300; 
 }
 
$fuente_principal: 'Ebrima', arial;
$fuente_sec: 'Ebrima', arial;

// Tamaño de Media Queries
$telefono: 480px;
$tablet: 768px;
$desktop: 1024px;

// Colores
$negro: #000000;
$blanco: #FFFFFF;
$gris: #e1e1e1;
$grisOscuro: #333333;
$rosaclaro: #F78FB0;
$rosaoscuro: #f26f98;;
$magenta: #E80062;

$separacion: 5rem;

// Fuentes

$delgada: 300;
$regular: 400;
$bold: 700;
$black: 900